import { Work } from "./Work";
import footprint from "../assets/footprint.png";
import oceanblue from "../assets/oceanbluets.png";

const works = [
  {
    name: "Footprint",
    image: footprint,
  },
  {
    name: "Ocean Blue",
    image: oceanblue,
  },
];

export const Works = () => {
  return (
    <div className="mt-24 justify-center animate__animated animate__fadeInUp animate__delay-0.5s">
      <div className="text-xs uppercase tracking-widest text-center py-1.5 px-2.5 text-slate-600">
        Works
      </div>

      <div className="mt-7 flex flex-wrap gap-6">
        {works.map((work) => (
          <Work {...work} />
        ))}
      </div>
    </div>
  );
};
