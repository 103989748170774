import { HashLink } from "react-router-hash-link";
import { Pricing } from "../components/Pricing";
import { Works } from "../components/Works";

const HomePage = () => {
  return (
    <div className="mt-10 px-10 xl:px-0 md:mt-20">
      <div className="animate__animated animate__fadeInUp">
        <h1 className="text-xl font-semibold text-slate-800 md:text-2xl">
          We are Cosma – A web development agency
        </h1>
        <p className="max-w-3/4 mt-2.5 text-base leading-7 text-slate-600 md:text-lg">
          Transforming your digital dreams into an online identity that's
          uniquely yours. With us, it's not just web development; it's an artful
          journey where innovation meets your brand's narrative. Your vision,
          our digital mastery – let's give your brand the online presence you
          deserve !
        </p>

        <HashLink
          to="/#pricing"
          className="inline-flex text-base text-white bg-accent py-2.5 px-3.5 mt-5 rounded md:text-lg"
        >
          Purchase a website{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(252, 252, 252, 1)" }}
          >
            <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
          </svg>
        </HashLink>
      </div>

      <Works />

      <div className="mt-28 animate__animated animate__fadeInUp">
        <h1 className="text-center text-3xl font-semibold text-slate-800 md:text-4xl">
          Web is the future
        </h1>

        <p className="text-base leading-8 max-w-2xl text-center text-slate-600 mt-3 mx-auto md:text-lg">
          In a world where online presence is paramount, a website from Cosma
          isn't just an asset – it's a necessity. Elevate your business with a
          global platform, enhance credibility, and showcase your uniqueness.
        </p>
      </div>

      <Pricing />
    </div>
  );
};

export default HomePage;
