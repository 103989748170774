import React, { useState } from "react";
import { packages } from "../packages";
import { Package } from "./Package";

export const Pricing = () => {
  const [tier, setTier] = useState("Basic Tier");
  const filteredPackages = packages.filter(
    (pricePackage) => pricePackage.tier === tier
  );

  return (
    <div
      id="pricing"
      className="mt-24 animate__animated animate__fadeInUp animate__delay-0.5s"
    >
      <div className="text-xs uppercase tracking-widest text-center py-1.5 px-2.5 text-slate-600">
        Pricing
      </div>

      <h1 className="text-center text-3xl font-semibold text-slate-800 md:text-4xl">
        Make the best purchase
      </h1>
      <p className="text-center text-md text-slate-600 mt-2.5 md:text-lg">
        Choose a package that best fit your needs.
      </p>

      <div className="segmented">
        <button
          onClick={() => setTier("Basic Tier")}
          style={
            tier === "Basic Tier"
              ? { backgroundColor: "#ffffff" }
              : { backgroundColor: "#e3e3e3" }
          }
        >
          Basic Tier
        </button>
        <button
          onClick={() => setTier("Business Tier")}
          style={
            tier === "Business Tier"
              ? { backgroundColor: "#ffffff" }
              : { backgroundColor: "#e3e3e3" }
          }
        >
          Business Tier
        </button>
      </div>
      <div className="mt-7 flex flex-col flex-wrap justify-center gap-5 md:flex-row">
        {filteredPackages.map((pricePackage) => (
          <Package key={pricePackage.name} {...pricePackage} />
        ))}
      </div>
    </div>
  );
};
