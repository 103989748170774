import React from "react";
import { Link } from "react-router-dom";

export const Package = ({ tier, name, price, prevPrice, features }) => {
  return (
    <div className="border border-slate-200 p-7 rounded-lg">
      <h2 className="text-lg mb-5">{name}</h2>

      <h3 className="text-xs uppercase tracking-wider text-slate-500 mb-1.5">
        Starting From
      </h3>

      <h1 className="text-4xl font-semibold">
        {price} <span className="text-xs">GHC</span>
      </h1>

      <div className="mt-7">
        <ul>
          {features.map((feature) => (
            <li className="flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgba(171, 173, 183, 1)" }}
              >
                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
              </svg>{" "}
              {feature}
            </li>
          ))}
        </ul>

        <Link
          to="/contact"
          className="text-white bg-accent flex justify-between py-3 px-6 mt-7 rounded-lg"
        >
          Get a quote{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(255, 255, 255, 1)" }}
          >
            <path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
          </svg>
        </Link>
      </div>
    </div>
  );
};
