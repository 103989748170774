import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="mt-14 py-14">
      <div className="max-w-5xl mt-0 mx-auto text-center">
        <Link
          to="/contact"
          className="text-sm text-slate-400 hover:text-slate-500 underline"
        >
          Contact
        </Link>
        <div className="mt-3.5 text-slate-400 text-sm">
          © {new Date().getFullYear()} Cosma. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
