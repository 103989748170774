import React from "react";

export const Work = ({ name, image }) => {
  return (
    <div className="mb-10">
      <div
        className="w-60 h-56 bg-slate-200 bg-cover rounded-md md:w-80"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <h1 className="mt-2.5 text-lg">{name}</h1>
    </div>
  );
};
