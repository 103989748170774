export const packages = [
  {
    tier: "Basic Tier",
    name: "Starter Suite",
    price: 1000,
    prevPrice: 0,
    features: ["2 Pages", "Professional Emails", "Domain and Hosting"],
  },
  {
    tier: "Basic Tier",
    name: "PrimeWeb Suite",
    price: 1800,
    prevPrice: 0,
    features: [
      "3-5 Pages",
      "Professional Emails",
      "Domain and Hosting",
      "Search Engine Optimisation (SEO)",
    ],
  },
  {
    tier: "Basic Tier",
    name: "BizEssentials Suite",
    price: 2400,
    prevPrice: 2800,
    features: [
      "5 Pages",
      "Professional Emails",
      "Domain and Hosting",
      "Search Engine Optimisation (SEO)",
    ],
  },
  {
    tier: "Business Tier",
    name: "CMS Mastery Suite",
    price: 3000,
    prevPrice: 0,
    features: [
      "3+ Pages, 1000ghc per page",
      "Professional Emails",
      "Domain and Hosting",
      "Search Engine Optimisation (SEO)",
    ],
  },
  {
    tier: "Business Tier",
    name: "E-Commerce Insight Suite",
    price: 3000,
    prevPrice: 0,
    features: [
      "Informational (Without payment system)",
      "Professional Emails",
      "Domain and Hosting",
      "Search Engine Optimisation (SEO)",
    ],
  },
  {
    tier: "Business Tier",
    name: "E-Commerce Pro Suite",
    price: 6000,
    prevPrice: 0,
    features: [
      "3 non-store pages",
      "Incl. payment system",
      "Professional Emails",
      "Domain and Hosting",
      "Search Engine Optimisation (SEO)",
    ],
  },
];
