import "./App.css";
import "animate.css";
import bg from "./assets/bg.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="bg-cover" style={{ backgroundImage: `url(${bg})` }}>
        <NavBar />
        <div className="max-w-6xl mt-0 mx-auto">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
