import { useForm, ValidationError } from "@formspree/react";

const ContactPage = () => {
  const [state, handleSubmit] = useForm("xgejbbdp");
  if (state.succeeded) {
    return (
      <p>
        Thanks for contacting us! We will get back to you as soon as possible.
      </p>
    );
  }
  return (
    <div className="mt-7 px-10 xl:px-0">
      <h1 className="text-center text-3xl font-semibold md:text-4xl">
        Contact
      </h1>
      <p className="text-center text-base leading-6 mt-6 text-slate-600 md:text-lg md:leading-7">
        Got an amazing idea you'd like to run by us? Don't hesitate to reach out
        to us
      </p>

      <div className="mt-6 flex justify-center items-center gap-8">
        <a
          className="flex items-center gap-2 text-slate-600 hover:underline"
          href="mailto:thecosmabrand@gmail.com?subject=Request for quote"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(83, 83, 83, 1)" }}
          >
            <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path>
          </svg>
          Email
        </a>
        <a
          className="flex items-center gap-2 text-slate-600 hover:underline"
          href="https://wa.me/233248185717"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(83, 83, 83, 1)" }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
            ></path>
          </svg>
          Call/WhatsApp
        </a>
      </div>

      <div className="max-w-lg mx-auto">
        <h1 className="text-center text-xl font-semibold mt-10 mb-6 md:text-2xl">
          Get a quote
        </h1>

        {/* <div className="mb-8 text-center text-green-500 bg-green-100 py-5 px-8 rounded-md border border-green-300">
        </div> */}
        <form onSubmit={handleSubmit}>
          <input
            className="w-full py-2.5 pl-4 mb-3 rounded-sm bg-slate-300"
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <input
            className="w-full py-2.5 pl-4 mb-3 rounded-sm bg-slate-300"
            type="email"
            placeholder="Email"
            required
            name="email"
            id="email"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <textarea
            className="w-full pt-2.5 pl-2.5 mb-3 rounded-sm h-40 bg-slate-300"
            placeholder="Tell us about your business or project"
            required
            name="message"
            id="message"
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button
            className="w-full bg-accent py-2.5 px-4 text-white font-semibold cursor-pointer rounded-sm"
            type="submit"
            disabled={state.submitting}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
