import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import { Socials } from "./Socials";

const NavBar = () => {
  return (
    <nav className="max-w-6xl mt-0 mx-auto px-10 flex justify-between items-center py-7 animate__animated animate__fadeInDown xl:px-0">
      <Link to="/">
        <img className="w-16" src={logo} alt="logo" />
      </Link>

      <Socials />
    </nav>
  );
};

export default NavBar;
